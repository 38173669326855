<!--
 * @Author: LazyQ
 * @Date: 2020-04-14 16:49:10
 * @LastEditTime: 2020-04-20 14:19:46
 * @LastEditors: LazyQ
 -->
 <style lang="less" scoped>
.tab-change {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  .top-margin {
    width: 12.5px;
  }
}
</style>
<template>
  <div class="tab-change">
    <slot name="custom"></slot>
    <slot>
      <a-input-search
        v-show="hasSearch"
        placeholder="请输入关键词"
        style="width: 218px"
        @search="onSearch"
      />
      <div class="top-margin"></div>
      <a-button type="primary" v-show="hasAdd" icon="plus" @click="onSuccess">添加</a-button>
    </slot>
  </div>
</template>

<script>
export default {
  name: "TabAction",
  props: {
    hasSearch: {
      type: Boolean,
      default: true
    },
    hasAdd: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
    };
  },
  created() {
    this.title = this.$route.meta.title;
  },
  methods: {
    onSearch(e) {
      this.$emit("search", e);
    },
    onSuccess(e) {
      this.$emit("success", e);
    },
    handleChange(e) {
      this.$emit("handleChange", e);
    }
  }
};
</script>